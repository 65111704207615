<template>
  <AppWrapper
    data-vue-component-name="PrintSamples"
    bg-image="backgrounds/print-samples.jpg"
  >
    <transition name="fade" mode="out-in">
      <PrintSamplesMultipleView
        v-if="!activeCard"
        @on-card-click="setActiveCard"
      />

      <PrintSamplesSingleView
        v-else
        :card="activeCard"
        @on-close="setActiveCard(null)"
      />
    </transition>
  </AppWrapper>
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { AppWrapper } from '@xsys2/components';
import { metaController } from '@/modules';

metaController({
  title: 'Print Samples Page',
  link: [
    {
      rel: 'preload',
      href: 'backgrounds/print-samples.jpg',
      as: 'image',
    },
  ],
});

const store = useStore();
store.dispatch('printSamples/getData');

const activeCard = ref(null);
const setActiveCard = card => (activeCard.value = card);
</script>

<style scoped lang="scss">
[data-vue-component-name="PrintSamples"] {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  [data-vue-component-name="PrintSamplesMultipleView"],
  [data-vue-component-name="PrintSamplesSingleView"] {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
}
</style>
